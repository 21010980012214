// @ts-nocheck
/* eslint-enable */
// App
import React, { useEffect, useRef, useState } from 'react';

const LazyLoad = ({
  children,
  threshold = 0.0,
  rootMargin = '50px',
  height = '100px',
  width = '100px',
  placeholderClassname = '',
  customPlaceholder = null,
  customId = '',
}) => {
  // React refs
  const observer = useRef(null);
  const el = useRef(null);

  // React state
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const options = {
      root: null,
      rootMargin,
      threshold,
    };

    if (window) {
      observer.current = new IntersectionObserver(([entry]) => {
        if (entry.isIntersecting) {
          if (el.current && observer.current) {
            observer.current.unobserve(el.current);
          }
          setIsVisible(true);
        }
      }, options);

      // Start observing
      if (el.current) {
        observer.current.observe(el.current);
      }
    }

    return () => {
      if (observer.current) {
        observer.current.disconnect();
      }
    };
  }, []);

  const style = {
    height,
    width,
  };

  return isVisible ? (
    children
  ) : (
    <div ref={el} style={style} id={customId} className={placeholderClassname}>
      {customPlaceholder}
    </div>
  );
};

export default LazyLoad;
