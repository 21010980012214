import styled from 'styled-components';
import { viewports } from 'app/shared/styles/_breakpoints';
import { colors } from 'app/shared/styles/_colors';
import { font } from 'app/shared/styles/_fontsize';
import { zindex } from 'app/shared/styles/_zindex';
import { noUserSelect } from 'app/shared/styles/_mixins';

// Base components
import Linker from 'app/shared/modules/Linker';

const HAMBURGER_MENU_WIDTH = '255px';

interface HamburgerProps {
  isHamburgerOpen?: boolean;
}

export const HamburgerMenuOverlay = styled.div<HamburgerProps>`
  ${(props) =>
    props.isHamburgerOpen &&
    `
        position: fixed;
        top: 0;
        z-index: ${zindex['$z-index-max']};
        background: ${colors['$hpx-white']};
        opacity: 0.8;
        width: 100%;
        height: 100%;
        overflow: hidden;
        cursor: pointer;
        left: 0;
    `}
`;

export const HamburgerMenu = styled.nav<HamburgerProps>`
  position: fixed;
  z-index: ${zindex['$z-index-max']};
  top: 0;
  width: ${HAMBURGER_MENU_WIDTH};
  height: 100%;
  transition: transform ease 250ms;
  background: ${colors['$hpx-white']};
  overflow: hidden;
  left: -${HAMBURGER_MENU_WIDTH};

  ${(props) =>
    props.isHamburgerOpen &&
    `
        transform: translate3d(${HAMBURGER_MENU_WIDTH}, 0, 0);
        overflow-y: auto;
        border-right: 1px solid ${colors['$hpx-grey-500']};
    `};
`;

export const HamburgerMenuSectionHeader = styled.p`
  ${font.sm};
  background: ${colors['$hpx-teal-500']};
  border-top: none;
  color: ${colors['$hpx-white']};
  font-weight: bold;
  padding: 15px 20px;
  width: 100%;
`;

export const HamburgerMenuList = styled.ul`
  margin: 0;
`;

export const HamburgerMenuListItem = styled.li`
  display: block;
  line-height: 1;
  margin: 0;
  padding: 0;
  position: relative;
  width: 100%;
  height: 54px;
  > * {
    border-bottom: none;
    color: ${colors['$hpx-blue-600']};
    display: block;
    padding: 15px 20px;
    width: 100%;
    height: 100%;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    &:hover {
      color: ${colors['$hpx-blue-600']};
      text-decoration: none;
    }
    &:focus {
      outline-offset: -1px;
      text-decoration: none;
    }
  }

  &:hover {
    background-color: ${colors['$hpx-grey-100']};
  }
`;

export const HamburgerMenuModalButton = styled.button`
  ${noUserSelect};
  cursor: pointer;
  text-decoration: none;
  background: ${colors['$hpx-white']};
  text-align: left;
  &:hover {
    background: inherit;
  }
  display: flex;
`;

export const LogoContainer = styled.a`
  text-align: center;
  border-bottom: 1px solid ${colors['$hpx-grey-500']};
  padding-top: 10px;
  padding-bottom: 5px;
  display: block;
  &:focus {
    outline-offset: -1px;
  }
`;

export const Logo = styled.img`
  cursor: pointer;
  height: 40px;
  @media ${viewports['md-and-up']} {
    height: 48px;
  }
`;

export const MenuIcon = styled.img`
  margin-right: 10px;
  height: 20px;
  vertical-align: text-bottom;
`;

export const MenuSpanText = styled.span`
  ${font.sm};
  font-weight: 300;
`;

export const HamburgerMenuListItemLogOut = styled(HamburgerMenuListItem)`
  border-top: 1px solid ${colors['$hpx-grey-500']};
`;

export const StyledLinker = styled(Linker)`
  height: 53px;
  display: flex;
  align-items: center;
`;

export const ImgSpanContainer = styled.span`
  width: 30px;
`;
